// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wave {
    position: relative;
}

.wave button::after, .wave a::after, .wave h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 3px;
    background-color: #000;
    border-radius: 5px;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .5s;
}

.wave button:hover::after, .wave a:hover::after, .wave h2:hover::after {
    transform-origin: left;
    transform: scaleX(1);
}`, "",{"version":3,"sources":["webpack://./src/components/Wave.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,YAAY;IACZ,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,uBAAuB;IACvB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,oBAAoB;AACxB","sourcesContent":[".wave {\n    position: relative;\n}\n\n.wave button::after, .wave a::after, .wave h2::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    bottom: -1px;\n    width: 100%;\n    height: 3px;\n    background-color: #000;\n    border-radius: 5px;\n    transform-origin: right;\n    transform: scaleX(0);\n    transition: transform .5s;\n}\n\n.wave button:hover::after, .wave a:hover::after, .wave h2:hover::after {\n    transform-origin: left;\n    transform: scaleX(1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
