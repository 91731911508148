// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-label {
    width: 50%;
    height: 33px;
    line-height: 34px;
    cursor: pointer;
    z-index: 20;
    transition: background-color 0.3s;
    color: #9c9c9c;
}

#guardian:checked + .toggle-label,
#ward:checked + .toggle-label {
    color: #000;
}

.slider {
    position: absolute;
    height: 45px;
    top: -3px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 1px 1px rgb(163, 153, 115);
    border-radius: 20px;
    transition: left 0.3s, right 0.3s;
}

#guardian:checked ~ .slider {
    left: 0px;
    right: 53%;
}

#ward:checked ~ .slider {
    right: 0px;
    left: 45%;
    width: 108px;
}
`, "",{"version":3,"sources":["webpack://./src/components/guardians/Guardian/Toggle/Toggle.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,iCAAiC;IACjC,cAAc;AAClB;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,yBAAyB;IACzB,8CAA8C;IAC9C,mBAAmB;IACnB,iCAAiC;AACrC;;AAEA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,UAAU;IACV,SAAS;IACT,YAAY;AAChB","sourcesContent":[".toggle-label {\n    width: 50%;\n    height: 33px;\n    line-height: 34px;\n    cursor: pointer;\n    z-index: 20;\n    transition: background-color 0.3s;\n    color: #9c9c9c;\n}\n\n#guardian:checked + .toggle-label,\n#ward:checked + .toggle-label {\n    color: #000;\n}\n\n.slider {\n    position: absolute;\n    height: 45px;\n    top: -3px;\n    background-color: #FFFFFF;\n    box-shadow: 2px 2px 1px 1px rgb(163, 153, 115);\n    border-radius: 20px;\n    transition: left 0.3s, right 0.3s;\n}\n\n#guardian:checked ~ .slider {\n    left: 0px;\n    right: 53%;\n}\n\n#ward:checked ~ .slider {\n    right: 0px;\n    left: 45%;\n    width: 108px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
