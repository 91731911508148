// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("small_flower.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainSwiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
}

.mainSwiper .swiper-pagination-bullet {
    background-color: #FEEBA4 !important;
    opacity: 1;
    width: 20px;
    height: 20px;
    border: rgb(127, 91, 61) solid 2.5px;
    margin-bottom: 15px !important;
}

.mainSwiper .swiper-pagination-bullet-active {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-color: transparent !important;
    border-radius: 0px;
    border: 2px solid transparent;
    width: 35px;
    height: 35px;
    background-size: cover; 
    position: relative;
    right: 9px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Main/Main.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,UAAU;IACV,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,8BAA8B;AAClC;;AAEA;IACI,yDAAyC;IACzC,4BAA4B;IAC5B,wCAAwC;IACxC,kBAAkB;IAClB,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".mainSwiper .swiper-slide {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: none;\n}\n\n.mainSwiper .swiper-pagination-bullet {\n    background-color: #FEEBA4 !important;\n    opacity: 1;\n    width: 20px;\n    height: 20px;\n    border: rgb(127, 91, 61) solid 2.5px;\n    margin-bottom: 15px !important;\n}\n\n.mainSwiper .swiper-pagination-bullet-active {\n    background-image: url('small_flower.png');\n    background-repeat: no-repeat;\n    background-color: transparent !important;\n    border-radius: 0px;\n    border: 2px solid transparent;\n    width: 35px;\n    height: 35px;\n    background-size: cover; \n    position: relative;\n    right: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
