import React from 'react';
import SpeechToText from '../../components/Ward/SpeechToText';

const WardPage = () => {
    return (
        <div>
            <SpeechToText/>
        </div>
    );
};

export default WardPage;