// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker {
    border-radius: 15px !important;
    border: 2px solid white;
}

.react-datepicker__header {
    border-bottom: none;
    border-top-right-radius: 10px !important; 
    border-top-left-radius: 10px !important; 
    background-color: #FEEBA4;
}

.react-datepicker__day--outside-month {
    cursor: default;
    visibility: hidden;
}

.react-datepicker__month-container {
    font-size: 1.1rem; 
    width: 450px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 3em; 
    line-height: 2.5em; 
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #FEEBA4; 
    color: black;
}

.react-datepicker__day:hover {
    background-color: #FEEBA4; 
}`, "",{"version":3,"sources":["webpack://./src/components/Calendar/Calendar.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,wCAAwC;IACxC,uCAAuC;IACvC,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;;;IAGI,UAAU;IACV,kBAAkB;AACtB;;AAEA;;IAEI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".react-datepicker {\n    border-radius: 15px !important;\n    border: 2px solid white;\n}\n\n.react-datepicker__header {\n    border-bottom: none;\n    border-top-right-radius: 10px !important; \n    border-top-left-radius: 10px !important; \n    background-color: #FEEBA4;\n}\n\n.react-datepicker__day--outside-month {\n    cursor: default;\n    visibility: hidden;\n}\n\n.react-datepicker__month-container {\n    font-size: 1.1rem; \n    width: 450px;\n}\n\n.react-datepicker__day-name,\n.react-datepicker__day,\n.react-datepicker__time-name {\n    width: 3em; \n    line-height: 2.5em; \n}\n\n.react-datepicker__day--selected,\n.react-datepicker__day--keyboard-selected {\n    background-color: #FEEBA4; \n    color: black;\n}\n\n.react-datepicker__day:hover {\n    background-color: #FEEBA4; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
